import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import styles from './Recommendations.module.css';

const Recommendations = () => {
  return (
    <div id="recs" className={styles.recContainer}>
      <Container className={styles.recContent}>
        <h2>Recs</h2>
        <Carousel className={styles.carousel} interval={null}>
          <Carousel.Item>
            <div className={styles.carouselItemHeader}>
              <img className={styles.profileImgPNG} src="../person.png" />
              <div>
                <a
                  href="https://www.linkedin.com/in/trey-joines-70166412"
                  target="_blank"
                >
                  <h3>Trey Joines</h3>
                </a>
                <h5>CEO // Vanir Installed Sales LLC</h5>
                <p>
                  <i>Trey managed Evan directly.</i>
                </p>
              </div>
            </div>
            <p>
              "I can honestly say that Evan Garris is one of the people that I
              admire most not only as an employee, or as a leader in my company,
              but as a friend as well. He spent nearly five years of his life
              helping me take an idea for a company and turning it into one of
              premier construction management companies in the Carolinas, and I
              cannot thank him enough. Of note: during those five years Evan
              continued his education with his own time and money to acquire
              skills to make himself and our company better. My only regret is
              that I couldn't hold onto him longer; however, his skill and
              passions outgrew what my company could offer.
            </p>
            <p>
              Evan's true talent is taking real world complex problems, solving
              them with innovative thinking and initiative, and implementing the
              solution with a non-invasive, well thought out strategy. For
              reference, Evan spearheaded many projects for me that helped
              transform a "good ole boy" construction company into a
              professional, connected, tech savvy enterprise with limitless
              ability to scale that attracts a young educated and very diverse
              staff.
            </p>
            <p>
              I don't give recommendations for past employees if I don't believe
              in them. I can only tell prospective employers the truth. The
              truth on Evan is that he would be an executive in my company were
              it not for his drive and passion for continued professional growth
              with a growing tech company. It would be a foolish mistake to not
              give Evan full consideration for any job that he has taken the
              time to show interest in. He will quickly advance into a
              leadership role with any company lucky enough to have him."
            </p>
          </Carousel.Item>

          <Carousel.Item>
            <div className={styles.carouselItemHeader}>
              <img
                className={styles.profileImg}
                src="https://media-exp1.licdn.com/dms/image/C4D03AQHfHLGY_BbNqw/profile-displayphoto-shrink_800_800/0/1572644400670?e=1656547200&v=beta&t=tFuvmrHoT_prvAv9HCF-TWal--3rO8cTv9_ogr-lmng"
              />
              <div>
                <a
                  href="https://www.linkedin.com/in/jameswneal/"
                  target="_blank"
                >
                  <h3>Jim Neal</h3>
                </a>
                <h5>Corporate Advisor // Agema Group LLC</h5>
                <p>
                  <i>Jim is Evan's mentor.</i>
                </p>
              </div>
            </div>
            <p>
              "Evan has been a friend of 15 years. We first met working on
              political and social justice initiatives in our native North
              Carolina. Evan is a passionate, ambitious, even-headed,
              intellectual cum quasi-nerdish and first-rate human being. His
              character is exemplary and he is smart as hell. That aside I'd
              like to speak to those considering hiring Evan.
            </p>
            <p>
              Evan is ambitious. He wants to be your boss in the future and you
              should celebrate that. Evan is driven to succeed and make his mark
              in the realm of software engineering. He works extremely hard, and
              though he does not like to be wrong, he does not have to be right.
              He just strives to get it right. Evan takes great pride in his
              work- and is the sort of prospective team member who you will want
              to present with challenges. He needs to see avenues of career
              growth. Evan has excellent EIQ and has absolutely no difficulty
              leading complex projects with diverse team members. He is also a
              superb communicator. I know of no one who speaks ill of Evan- even
              those who don't like him ;-).
            </p>
            <p>
              And lastly consider that Evan Garris passed on a promotion to a
              high-paying position as COO of a growing business because he had
              such a passion for software engineering. He resigned, took out a
              bank loan and enrolled full-time at Full Stack Academy late last
              year. In his prior position Evan spent an inordinate amount of
              time championing the use of technological tools within a
              closely-held, family business. His contributions helped drove its
              growth. Give him consideration- and then hire him. You won’t
              regret your decision."
            </p>
          </Carousel.Item>

          <Carousel.Item>
            <div className={styles.carouselItemHeader}>
              <img
                className={styles.profileImg}
                src="https://media-exp1.licdn.com/dms/image/C4E03AQFn5vNYMO-psw/profile-displayphoto-shrink_800_800/0/1517732948853?e=1656547200&v=beta&t=tR4sPwEL0QDK1F_21nEuRHGQoFUD8sqtEXtZqPE_jao"
              />
              <div>
                <a
                  href="https://www.linkedin.com/in/rob-watkins-3397938/"
                  target="_blank"
                >
                  <h3>Rob Watkins</h3>
                </a>
                <h5>Software Engineer</h5>
                <p>
                  <i>Rob worked with Evan on the same team.</i>
                </p>
              </div>
            </div>
            <p>
              "I worked with Evan on a group project in which we built a
              shopping website. The final product turned out great and Evan's
              leadership and team-player mentality were a huge part of our
              success. He is highly skilled in Javascript, most notably React,
              Express, and Redux. He is more than willing to help others and is
              always looking to build his knowledge further. Evan is concise,
              organized, hardworking, and has the software skills to back it all
              up."
            </p>
          </Carousel.Item>

          <Carousel.Item>
            <div className={styles.carouselItemHeader}>
              <img
                className={styles.profileImg}
                src="https://media-exp1.licdn.com/dms/image/D4D03AQGXBBgE0Cyjjw/profile-displayphoto-shrink_800_800/0/1647309005412?e=1656547200&v=beta&t=TNZrGBT73pJnTrt4_WyCI_rQIx3lpsbubYasIOcL46w"
              />
              <div>
                <a
                  href="https://www.linkedin.com/in/ryanho2012/"
                  target="_blank"
                >
                  <h3>Ryan Ho</h3>
                </a>
                <h5>Software Engineer</h5>
                <p>
                  <i>Ryan worked with Evan on the same team.</i>
                </p>
              </div>
            </div>
            <p>
              "Evan is a masterful and crafty developer. Together we worked on a
              PERN app and he was nothing less than excellent to work with. I
              would describe him as diligent, patient and thoughtful. Our work
              flow back and forth was excellent and I could not have asked for a
              better pair programming partner!"
            </p>
          </Carousel.Item>

          <Carousel.Item>
            <div className={styles.carouselItemHeader}>
              <img
                className={styles.profileImg}
                src="https://media-exp1.licdn.com/dms/image/C4E03AQHURdLdaz1CnA/profile-displayphoto-shrink_800_800/0/1648327754802?e=1656547200&v=beta&t=xBEpZXgR9_LfEj3lTxPp6lsrbMZXV-R3DLWT75wJ6Uo"
              />
              <div>
                <a
                  href="https://www.linkedin.com/in/sulaiman-marey/"
                  target="_blank"
                >
                  <h3>Sulaiman Marey</h3>
                </a>
                <h5>Software Engineer</h5>
                <p>
                  <i>Sulaiman worked with Evan on the same team.</i>
                </p>
              </div>
            </div>
            <p>
              "Studying together at Fullstack academy, Evan has always set the
              bar high for ability to learn new technologies quickly and
              communicate these technologies with his team. Evan skilled in
              JavaScript, React, Redux, node js and his ability to work on and
              lead a team set him apart from most grads"
            </p>
          </Carousel.Item>
        </Carousel>
      </Container>
      <div className={styles.shapeDivider}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Recommendations;
