import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import styles from './Topbar.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Topbar = () => {
  return (
    <Navbar
      className={styles.navBar}
      fixed="top"
      expand="lg"
      variant="dark"
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="#welcome" className={styles.logo}>
          &lt;Evan Garris/&gt;
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <Nav.Link className={styles.links} href="#about">
              About
            </Nav.Link>
            <Nav.Link className={styles.links} href="#projects">
              Projects
            </Nav.Link>
            <Nav.Link className={styles.links} href="#resume">
              Resume
            </Nav.Link>
            <Nav.Link className={styles.links} href="#recs">
              Recs
            </Nav.Link>
            <Nav.Link className={styles.links} href="#contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Topbar;
