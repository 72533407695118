import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <div id="contact" className={styles.contactContainer}>
      <Container className={styles.contactContent}>
        <h2>Contact</h2>
        <p className={styles.contactText}>
          Please reach out regarding employment, networking or creative
          opportunities. All inquiries are welcome.
        </p>
        <div className={styles.logoContainer}>
          <div className={styles.logoDiv}>
            <a href="https://www.linkedin.com/in/evangarris/" target="_blank">
              <img src="/logos/linkedin.png" />
            </a>
          </div>
          <div className={styles.logoDiv}>
            <a href="https://github.com/egarris" target="_blank">
              <img src="/logos/github.png" />
            </a>
          </div>
          <div className={styles.logoDiv}>
            <a
              href="https://www.instagram.com/libidinaleconomist/"
              target="_blank"
            >
              <img src="/logos/instagram.svg" />
            </a>
          </div>
          <div className={styles.logoDiv}>
            <a href="mailto:egarris@gmail.com">
              <img src="/logos/email.png" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
