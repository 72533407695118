import React from 'react';
import { Container, Button } from 'react-bootstrap';
import styles from './Resume.module.css';

const Resume = () => {
  return (
    <div id="resume" className={styles.resumeContainer}>
      <Container className={styles.resumeContent}>
        <h2>Resume</h2>
        <p>Interested in working together? Click below to view my resume.</p>
        <Button
          className={styles.button}
          href="/evan_garris_resume.pdf"
          target="_blank"
          variant="danger"
        >
          PDF.open()
        </Button>
      </Container>
      <div className={styles.shapeDivider}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Resume;
