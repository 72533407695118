import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div id={styles.footer}>
      <Container id={styles.footerContainer}>
        <Container id={styles.footerContentContainer}>
          <div id={styles.siteContainer}>
            <p>SITE</p>
            <a className={styles.link} href="#welcome">
              Welcome
            </a>
            <a href="#about" className={styles.link}>
              About
            </a>
            <a href="#projects" className={styles.link}>
              Projects
            </a>
            <a href="#resume" className={styles.link}>
              Resume
            </a>
            <a href="#contact" className={styles.link}>
              Contact
            </a>
          </div>
        </Container>
        <Container id={styles.footerLogoContainer}>
          <h1 className={styles.logo}>
            <a id={styles.logoLink} href="/">
              &lt;Evan Garris/&gt;
            </a>
          </h1>
          <p id={styles.copyright}>Copyright © 2022</p>
        </Container>
      </Container>
    </div>
  );
};

export default Footer;
