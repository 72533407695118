import './App.css';
import Topbar from './Topbar';
import Welcome from './Welcome';
import About from './About';
import Projects from './Projects';
import Resume from './Resume';
import Recommendations from './Recommendations';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Topbar />
      <Welcome />
      <About />
      <Projects />
      <Resume />
      <Recommendations />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
