import React from 'react';
import { Container, Button } from 'react-bootstrap';
import styles from './Projects.module.css';

const Projects = () => {
  return (
    <div id="projects" className={styles.projectsContainer}>
      <Container className={styles.projectsContent}>
        <h2>Projects</h2>
        <Container className={styles.projects}>
          <div className={[styles.projectCard, styles.odd].join(' ')}>
            <div className={styles.projectBody}>
              <h3>Fullsnack</h3>
              <p>Software Engineer // March 2022 - April 2022</p>
              <ul>
                <li>
                  Collaborated with 3 other developers to design, build and
                  deploy a kitchen managment app in a 3-week timeframe.
                </li>
                <li>
                  Contributions: overall interface design (CSS & HTML/JSX),
                  database schema design, Express routing & Sequelize models,
                  React components related to recipes and Redux logic.
                </li>
                <li>
                  Tech Stack: React, Redux, Node, Postgres, Sequelize, Express,
                  Victory.js, React-Bootstrap, Edamam API
                </li>
              </ul>
              <div className={styles.projectLinks}>
                <Button
                  className={styles.singleLink}
                  variant="link"
                  href="https://github.com/Fullsnack-2201-CS-Team-06/Fullsnack"
                  target="_blank"
                >
                  GitHub
                </Button>
                <h5>|</h5>
                <Button
                  className={styles.singleLink}
                  variant="link"
                  href="https://fullsnack-fsa.herokuapp.com/"
                  target="_blank"
                >
                  Heroku
                </Button>
              </div>
            </div>
            <img className={styles.projectImg} src="/projects/fullsnack.png" />
          </div>

          <div className={[styles.projectCard, styles.even].join(' ')}>
            <img className={styles.projectImg} src="/projects/potstop.png" />
            <div className={styles.projectBody}>
              <h3>PotStop</h3>
              <p>Software Engineer // March 2022</p>
              <ul>
                <li>
                  Collaborated with 3 other developers to design, build and
                  deploy an e-commerce website in a 1.5-week timeframe.
                </li>
                <li>
                  Contributions: database schema design, Express routing,
                  Sequelize models, React components, Redux logic, CSS &
                  HTML/JSX.
                </li>
                <li>
                  Tech Stack: React, Redux, Node, Postgres, Sequelize, Express,
                  PayPal API
                </li>
              </ul>
              <div className={styles.projectLinks}>
                <Button
                  className={styles.singleLink}
                  variant="link"
                  href="https://github.com/TepigTroublemakers/graceShopper"
                  target="_blank"
                >
                  GitHub
                </Button>
                <h5>|</h5>
                <Button
                  className={styles.singleLink}
                  variant="link"
                  href="https://pot-stop.herokuapp.com/"
                  target="_blank"
                >
                  Heroku
                </Button>
              </div>
            </div>
          </div>

          <div className={[styles.projectCard, styles.odd].join(' ')}>
            <div className={styles.projectBody}>
              <h3>FortunaERP</h3>
              <p>Sole Engineer // March 2022 - Present</p>
              <ul>
                <li>
                  Leveraged business knowledge to create a small business
                  inventory and order management system.
                </li>
                <li>
                  Tech Stack: React, Redux, Node, Postgres, Sequelize, Express
                </li>
              </ul>
              <div className={styles.projectLinks}>
                <Button
                  className={styles.singleLink}
                  variant="link"
                  href="https://github.com/egarris/stackathon"
                  target="_blank"
                >
                  GitHub
                </Button>
              </div>
            </div>
            <div id={styles.fortunaContainer}>
              <h1 id={styles.fortuna}>fortuna</h1>
            </div>
          </div>
        </Container>
      </Container>
      <div className={styles.shapeDivider}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Projects;
