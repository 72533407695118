import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Welcome.module.css';

const Welcome = () => {
  return (
    <Container fluid id="welcome" className={styles.welcomeContainer}>
      <Container className={styles.welcome}>
        <Row className={styles.row}>
          <Col lg className={styles.row}>
            <img src="headshot_new.png" />
          </Col>
          <Col lg>
            <p className={styles.welcomeText}>Hi, I'm</p>
            <p id={styles.name}>Evan Garris</p>
            <p className={styles.welcomeText}>
              A <span id={styles.title}>software engineer</span> in Chicago, IL.
            </p>
          </Col>
        </Row>
      </Container>
      <div className={styles.shapeDivider}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div>
    </Container>
  );
};

export default Welcome;
