import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './About.module.css';

const About = () => {
  return (
    <div id="about" className={styles.aboutContainer}>
      <Container className={styles.aboutContent}>
        <h2>About</h2>
        <div className={styles.aboutTextContainer}>
          <p>
            <b>How is good software made in 2024?</b>
          </p>

          <p>
            Many would have you believe it's made like any other mass-produced
            good or service. Time, labor and knowledge combine to produce an
            artifact that meets business requirements with measurable outcomes
            that satisfy stakeholders. Many aspects of this process can be
            automated, perhaps de-skilled to the point where labor requirements
            are met by feeding cleverly-worded prompts to a large language
            model.
          </p>

          <p>
            This image of software development isn't false, but it's only half
            of the picture. It assumes that given a set of well-defined
            requirements, a successful product analytically follows in principle
            if not in practice. The missing half of the picture appears when we
            forgo the industrial analogy and understand software development as
            a craft, one whose materials may be less tangible than others, but
            whose scope and scale involve the same questions of sense and value.
          </p>

          <p>
            My experience has taught me never to lose touch with what makes
            software sensible. Whether I'm building a frontend or backend or
            piecing together cloud infrastructure, my focus is on how these
            systems encode a reality defined as much by the user's implicit
            desires and expectations as by explicit technical requirements. To
            stay close to the <i>feel</i> of software, to sense when and where
            complexity demands simplification or complication: this is what
            gives software traction over its use case and is the origin of its
            value.
          </p>

          <p>
            This is how I approach my work as a software engineer at{' '}
            <a href="https://www.redfoundry.com/">Red Foundry</a>, a digital
            consultancy in Chicago, IL. I work hard to build products for our
            clients that extend their power to act on the world and keep them
            in touch with what matters, no matter if they're large
            enterprises or small entrepreneurs. What I do is first and foremost
            an experience of collaboration and synthesis that isn't only
            rational. It's something creative and often improvisational that has
            yet to be automated and from which I derive great joy.
          </p>

          <p>
            How is good software made in 2024? With good engineers. No matter
            what technical innovation has in store for the future of software
            development, I hope I've given you a first sense of what makes my
            labor valuable. If I have, I look forward to whatever opportunities
            the future holds for us.
          </p>
        </div>
        <div className={styles.logosContainer}>
          <img src="/logos/javascript.png" />
          <img src="/logos/python.png" />
          <img src="/logos/react.png" />
          <img src="/logos/redux.png" />
          <img src="/logos/express.png" />
          <img src="/logos/node.png" />
          <img src="/logos/postgres.png" />
          <img src="/logos/r.png" />
        </div>
      </Container>
      <div className={styles.shapeDivider}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className={styles.shapeFill}
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default About;
